import { getLayout } from "@/components/Layout";
import { Section, SectionHeading } from "@/components/Section";
import { AMPLITUDE_EVENTS, logEvent } from "@/lib/amplitude";
import { HeroSection } from "@/slices/HeroSection";
import { Button, Text } from "@chakra-ui/react";
import useTranslation from "next-translate/useTranslation";
import NextLink from "next/link";
import * as React from "react";

const Custom404 = () => {
  const { t } = useTranslation("404");

  React.useEffect(() => {
    logEvent(AMPLITUDE_EVENTS.notFoundPageView, { path: window?.location?.pathname });
  }, []);

  return (
    <>
      <HeroSection
        slice={{
          variation: "small",
        }}
        heroImage={{ url: "/hero.jpeg" }}
      />
      <Section>
        <SectionHeading>{t("heading")}</SectionHeading>
        <Text mb={10}>{t("subtitle")}</Text>
        <NextLink href="/" passHref legacyBehavior>
          <Button as="a" maxW="max-content" colorScheme="primary" alignSelf="center">
            {t("common:home")}
          </Button>
        </NextLink>
      </Section>
    </>
  );
};

Custom404.getLayout = getLayout;

export default Custom404;
